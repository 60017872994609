import $ from "jquery";

export default function () {
  var menuOuvert = false;

  $(window).scroll(function () {
    $(".mobile_menu").removeClass("active");
  });

  $("#hamburger-icon").click(function (event) {
    event.stopPropagation();
    if (!menuOuvert) {
      $(".mobile_menu").addClass("active");
      menuOuvert = true;
    } else {
      $(".mobile_menu").removeClass("active");
      menuOuvert = false;
    }
    event.stopPropagation();
  });

  $("body").click(function () {
    if (menuOuvert) {
      $(".mobile_menu").removeClass("active");
      menuOuvert = false;
    }
  });
}
