import "./modules/helpers";
import start from "./modules/router";
import mobileNav from "./modules/mobileNav";
import mainMenu from "./modules/mainMenu";
import mobileMenu from "./modules/mobileMenu";
import loader from "./modules/loader";
import scroller from "./modules/scroller";
import accordeon from "./modules/accordeon";
import responsiveEmbed from "./modules/responsiveEmbed";

mobileNav();
loader();
mainMenu();
scroller();
accordeon();
responsiveEmbed();
mobileMenu();
