import $ from "jquery";

export default function () {
  $(".js-main-menu-button").on("mouseenter", function (e) {
    e.stopPropagation();
    const $el = $(this);
    const parentId = $el.attr("data-parent");
    $(`.js-main-menu-submenu`).removeClass("active");
    const distance =
      $el.offset().left - $(".c-header .container").offset().left;
    // console.log($el[0].getBoundingClientRect().x);
    const $submenu = $(`.js-main-menu-submenu[data-parent="${parentId}"]`).css(
      "left",
      distance - 26
    );
    $submenu.addClass("active");
  });

  $(".c-main-menu__item").on("mouseenter", function () {
    $(".js-main-menu-submenu").removeClass("active");
  });

  $("body").on("mouseleave", ".js-main-menu-submenu", function () {
    $(".js-main-menu-submenu").removeClass("active");
  });
}
